.wrapper {
	/* width: min-content; */
	margin-bottom: 15px;
}

.dataPickerWrapper {
	display: flex;
	gap: 10px;
	align-items: flex-end;
}

.dataPickerWrapper button {
	height: 55px;
	width: 150px;
}

.table {
	width: 100%;
}

/* .dataList {
	display: flex;
	gap: 60px;
	flex-wrap: wrap;
	width: 100%;
	list-style-type: none;
}

.dataList li {
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-align: center;
	border: 1px solid teal;
	padding: 15px;
} */

@media (max-width: 1000px) {
	.wrapper {
		width: 100%;
	}
	section {
		padding: 0 20px;
	}
	.table {
		overflow-x: scroll;
	}
}

@media (max-width: 600px) {
	.dataPickerWrapper {
		flex-direction: column;
		align-items: center;
	}
}
