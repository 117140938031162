.topBar {
	width: 100%;
	height: 70px;
	background: white;
	position: sticky;
	top: 0;
	z-index: 999;
}

.topBarWrapper {
	height: 100%;
	padding: 0px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo {
	color: #2e7d32;
	font-weight: bold;
	cursor: pointer;
	font-size: 30px;
	padding: 0px 15px;
	line-height: 1.2em;
	/* border: 3px solid rgb(151, 30, 30); */
	border-radius: 8px;
	padding: 10px;
}

.topRight {
	display: flex;
	align-items: center;
}

.topBarIconContainer {
	position: relative;
	cursor: pointer;
	margin-right: 10px;
	color: #555;
}

.topIconBadge {
	width: 15px;
	height: 15px;
	position: absolute;
	top: -5px;
	right: 5px;
	background-color: red;
	color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
}

.topAvatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	cursor: pointer;
}
