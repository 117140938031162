.dataList {
	display: flex;
	gap: 60px;
	flex-wrap: wrap;
	padding: 0;
	/* width: 100%; */
	list-style-type: none;
}

.dataList li {
	display: flex;
	flex-direction: column;

	gap: 10px;
	text-align: center;
	border: 1px solid teal;
	padding: 15px;
}

@media (max-width: 1000px) {
	.dataList {
		gap: 30px;
	}
}

@media (max-width: 600px) {
	.dataList {
		gap: 10px;
	}
	.dataList li {
		width: 100%;
	}
}
